import React from "react"
import "./layout.css"
import Sidebar from './sidebar';
import { Link } from 'gatsby';

export default ({ children }) => (
  <div className="sidebar-layout">
    <div className="page-content">
      <header>
        <div className="more"></div>
        <h5 className='title'><Link to="/">Kiwi & Cachaute</Link></h5>
      </header>
      <Sidebar />

      <div className="large-container container content">
        {children}
      </div>
    </div>
  </div>
);