import React from "react"
import { Link } from "gatsby"

export default ({}) => (
  <div className="sidebar">
    <div className="sidebar-wrap">
      <div className="close"></div>

      <h5 className="title">
        <Link partiallyActive={true} to="/">
          Kiwi & Cachaute
        </Link>
      </h5>
      <h6>
        <Link partiallyActive={true} to="/">
          Jeremy & Ivette
        </Link>
      </h6>

      <div className="stories-wrap">
        <h6>
          <strong>Stories</strong>
        </h6>
        <ul className="stories">
          <li>
            <Link partiallyActive={true} to="/athens/">
              Europe
            </Link>
            <ul className="stories sub-stories">
              <li>
                <Link
                  partiallyActive={true}
                  to="/athens/"
                  activeClassName="active"
                >
                  Athens
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/rome/"
                  activeClassName="active"
                >
                  Rome
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/venice/"
                  activeClassName="active"
                >
                  Venice
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/florence/"
                  activeClassName="active"
                >
                  Florence
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/paris/"
                  activeClassName="active"
                >
                  Paris
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/amsterdam/"
                  activeClassName="active"
                >
                  Amsterdam
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/lisbon/"
                  activeClassName="active"
                >
                  Lisbon
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/barcelona/"
                  activeClassName="active"
                >
                  Barcelona
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link partiallyActive={true} to="/oaxaca/">
              Mexico
            </Link>
            <ul className="stories sub-stories">
              <li>
                <Link
                  partiallyActive={true}
                  to="/oaxaca/"
                  activeClassName="active"
                >
                  Oaxaca
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link partiallyActive={true} to="/south-island/">
              New Zeland
            </Link>
            <ul className="stories sub-stories">
              <li>
                <Link
                  partiallyActive={true}
                  to="/south-island/"
                  activeClassName="active"
                >
                  South Island
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/hawea/"
                  activeClassName="active"
                >
                  Hawea
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/nelson/"
                  activeClassName="active"
                >
                  Nelson
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/otago/"
                  activeClassName="active"
                >
                  Otago
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link partiallyActive={true} to="/great-ocean-road/">
              Australia
            </Link>
            <ul className="stories sub-stories">
              <li>
                <Link
                  partiallyActive={true}
                  to="/great-ocean-road"
                  activeClassName="active"
                >
                  Great Ocean Road
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/gold-coast/"
                  activeClassName="active"
                >
                  Gold Coast
                </Link>
              </li>
              <li>
                <Link
                  partiallyActive={true}
                  to="/sydney/"
                  activeClassName="active"
                >
                  Sydney
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link
              partiallyActive={true}
              to="/cambodia/"
              activeClassName="active"
            >
              Cambodia
            </Link>
          </li>
          <li>
            <a href="/tokyo.html">Tokyo</a>
          </li>
        </ul>
      </div>

      <div className="about-wrap">
        <ul className="about">
          <li>
            <Link partiallyActive={true} to="/about/" activeClassName="active">
              About
            </Link>
          </li>
          <li>
            <div className="location-home"></div>
            Mexico City
          </li>
        </ul>
      </div>
    </div>
  </div>
)
