import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO />

    <div className="row">
      <div className="twelve columns about-page">
        <h2>About</h2>

        <h6>
          <a href="https://ivetteyanezsoria.com" target="_blank">
            Ivette Yanez Soria
          </a>{" "}
          <div className="social-icons">
            <div className="social-icon instagram">
              <a href="https://www.instagram.com/ivi_yas/" target="_blank">
                Instagram
              </a>
            </div>
            <div className="social-icon twitter">
              <a href="https://twitter.com/ivi_yas" target="_blank">
                Twitter
              </a>
            </div>
            <div className="social-icon facebook">
              <a
                href="https://www.facebook.com/ivette.yanez.soria"
                target="_blank"
              >
                Facebook
              </a>
            </div>
          </div>
        </h6>

        <p>
          I take pictures to remind myself that the world is a beautiful place.
          Previously I have worked doing community engagement through social
          media, project management content creation for websites. My passion
          projects revolve around citizen participation, gender and equity. I
          have just completed a Master in International Development, which for
          me means turning what I care about into what I do now with my life.
        </p>

        <h6>
          <a href="https://jeremygeros.com" target="_blank">
            Jeremy Geros
          </a>{" "}
          <div className="social-icons">
            <div className="social-icon instagram">
              <a href="https://www.instagram.com/jeremygeros/" target="_blank">
                Instagram
              </a>
            </div>
            <div className="social-icon twitter">
              <a href="https://twitter.com/jeremygeros" target="_blank">
                Twitter
              </a>
            </div>
            <div className="social-icon facebook">
              <a href="https://www.facebook.com/jeremygeros" target="_blank">
                Facebook
              </a>
            </div>
          </div>
        </h6>

        <p>
          I am a self taught Ruby on Rails & React developer from New Zealand,
          currently based in Mexico City, Mexico. I am currently build random
          projects I find fun. I used to work as a senior software developer at{" "}
          <a target="_blank" href="https://www.aha.io">
            Aha!
          </a>{" "}
          Before that I started the language learning website{" "}
          <a target="_blank" href="https://govocab.com">
            Go Vocab
          </a>{" "}
          which is used by 20,000 high school students each year.
        </p>

        <div className="cameras">
          <h2>Cameras</h2>
          <ul>
            <li>Sony A7rII</li>
            <li>Nikon D5300</li>
            <li>iPhone 14 Pro Max</li>
            <li>iPhone 14 Pro Max</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)
